import React from "react"
import ContentLoader from "react-content-loader" 

const TableLoader = () => (
  <ContentLoader 
    speed={2}
    style={{ width: "100%", maxHeight:"400px"}}
    viewBox="0 0 400 400"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
  >
    <circle cx="79" cy="-96" r="8" /> 
    <rect x="136" y="-117" rx="5" ry="5" width="220" height="2" /> 
    <circle cx="8" cy="-139" r="8" /> 
    <rect x="8" y="-174" rx="5" ry="5" width="70" height="3" /> 
    <circle cx="-9" cy="-153" r="8" /> 
    <rect x="37" y="-182" rx="5" ry="5" width="92" height="4" /> 
    <circle cx="-131" cy="13" r="8" /> 
    <rect x="5" y="58" rx="0" ry="0" width="150" height="15" /> 
    <rect x="-93" y="253" rx="0" ry="0" width="8" height="61" /> 
    <rect x="136" y="-196" rx="5" ry="5" width="70" height="11" /> 
    <rect x="173" y="-212" rx="5" ry="5" width="70" height="11" /> 
    <rect x="238" y="-312" rx="5" ry="5" width="70" height="11" /> 
    <rect x="5" y="36" rx="0" ry="0" width="360" height="15" /> 
    <rect x="5" y="11" rx="5" ry="5" width="193" height="11" /> 
    <rect x="6" y="-180" rx="5" ry="5" width="378" height="2" /> 
    <rect x="5" y="85" rx="5" ry="5" width="360" height="4" /> 
    <rect x="8" y="448" rx="5" ry="5" width="378" height="2" /> 
    <rect x="8" y="463" rx="5" ry="5" width="378" height="2" /> 
    <rect x="8" y="478" rx="5" ry="5" width="378" height="2" /> 
    <rect x="8" y="493" rx="5" ry="5" width="378" height="2" /> 
    <rect x="161" y="58" rx="0" ry="0" width="60" height="15" /> 
    <rect x="230" y="58" rx="0" ry="0" width="60" height="15" /> 
    <rect x="299" y="58" rx="0" ry="0" width="64" height="15" /> 
    <rect x="5" y="110" rx="5" ry="5" width="360" height="4" /> 
    <rect x="6" y="135" rx="5" ry="5" width="360" height="4" /> 
    <rect x="5" y="160" rx="5" ry="5" width="360" height="4" /> 
    <rect x="5" y="185" rx="5" ry="5" width="360" height="4" /> 
    <rect x="6" y="210" rx="5" ry="5" width="360" height="4" /> 
    <rect x="4" y="235" rx="5" ry="5" width="360" height="4" /> 
    <rect x="4" y="260" rx="5" ry="5" width="360" height="4" /> 
    <rect x="5" y="285" rx="5" ry="5" width="360" height="4" /> 
    <rect x="4" y="310" rx="5" ry="5" width="360" height="4" /> 
    <rect x="4" y="335" rx="5" ry="5" width="360" height="4" /> 
    <rect x="5" y="360" rx="5" ry="5" width="360" height="4" /> 
    <rect x="5" y="384" rx="5" ry="5" width="360" height="4" /> 
    <rect x="5" y="409" rx="5" ry="5" width="360" height="4" /> 
    <rect x="6" y="434" rx="5" ry="5" width="360" height="4" />
  </ContentLoader>
)

export default TableLoader