import React from "react";
import LayoutDay from "../../../components/Chronology/LayoutDay";
import ContentLeft from "../../../components/Chronology/Modules/ContentLeft";
import ContentRight from "../../../components/Chronology/Modules/ContentRight";
import ModCCAATable from "../../../components/Chronology/Modules/ModCCAATable";
import ModTwoCols from "../../../components/Chronology/Modules/ModTwoCols";
import ModText from "../../../components/Chronology/Modules/ModText";
import ModReferenceList from "../../../components/Chronology/Modules/ModReferenceList";
import ReferenceRow from "../../../components/Chronology/Modules/ReferenceRow";
import ModDataEu from "../../../components/Chronology/Modules/ModDataEu";
import InlineLink from "../../../components/Chronology/Modules/InlineLink";
import ModCovidHighlight from "../../../components/Chronology/Modules/ModCovidHighlight";

export const frontmatter = {
  title: "Día -03",
  week: "Semana 0",
  day: "10",
  month: "Mar",
  monthNumber: "03",
  date: "2020-03-10",
  path: "/cronologia/semana-0#dia-10-mar",
};
const Day10M = (props) => {
  return (
    <LayoutDay frontmatter={frontmatter} {...props}>
      <ContentLeft>
        <ModCCAATable fecha={frontmatter.date} />
        <ModDataEu fecha={frontmatter.date} />
        <ModCovidHighlight>
          Chipre declara su primer cao de covid-19
        </ModCovidHighlight>
      </ContentLeft>

      <ContentRight>
        <ModText>
          En <strong>España</strong>, hasta el momento se han registrado 623 más
          que el día anterior y 35 fallecidos (2,2% de los casos positivos). El
          total de personas en UCI asciende a 100.
        </ModText>
        <ModText>
          El <strong>Consejo de Ministros</strong> ha aprobado un Real
          Decreto-ley que incluye{" "}
          <InlineLink link="https://www.lamoncloa.gob.es/consejodeministros/Paginas/enlaces/100320-enlace-trabajadore.aspx">
            medidas
          </InlineLink>{" "}
          para mejorar la protección de las personas trabajadoras afectadas por
          el COVID-19. Por otro lado, también se han aprobado una serie de
          medidas excepcionales para limitar la propagación y el contagio:
        </ModText>
        <ModTwoCols
          src="/images/svg/10_mar_aduana.svg"
          alt="restricciones viajes"
          small={true}
          inverted={false}
        >
          <strong>Restricciones en viajes:</strong> quedan prohibidos los vuelos
          directos de Italia a España. Seguirán pudiendo volar las aeronaves de
          Estado, los vuelos exclusivos de carga y todos los vuelos
          posicionales, humanitarios, médicos o de emergencia, y se podrán
          realizar escalas con fines no comerciales siempre que no permitan el
          descenso de pasajeros.
        </ModTwoCols>
        <ModTwoCols
          src="/images/svg/10_mar_autobus.svg"
          alt="suspendidos los viajes del Imserso"
          small={true}
          inverted={false}
        >
          Asimismo, han quedado{" "}
          <strong>suspendidos los viajes del Imserso</strong> durante el próximo
          mes.
        </ModTwoCols>
        <ModTwoCols
          src="/images/svg/10_mar_pista_tenis.svg"
          alt="Restricciones en actividades multitudinarias"
          small={true}
          inverted={false}
        >
          <strong>Restricciones en actividades multitudinarias:</strong> todos
          los grandes eventos deportivos profesionales y no profesionales, de
          competiciones nacionales e internacionales, se realizarán a puerta
          cerrada en toda España.
        </ModTwoCols>
        <ModText>
          En las <strong>zonas de transmisión significativa</strong> - Madrid,
          La Rioja, Vitoria y Labastida - han quedado suspendidas, además, las
          actividades colectivas en espacios cerrados con un aforo superior a
          mil personas. En caso de que estas actividades tengan lugar en
          espacios con un aforo menor, podrán celebrarse únicamente con una
          ocupación máxima de un tercio.
        </ModText>
        <ModReferenceList title="Guías y documentos publicados">
          <ReferenceRow
            link="https://www.lamoncloa.gob.es/consejodeministros/Paginas/enlaces/100320-enlace-trabajadore.aspx"
            name="Medidas establecidas por el Ministerio de Sanidad"
          />
        </ModReferenceList>
      </ContentRight>
    </LayoutDay>
  );
};
export default Day10M;
