import React, { useEffect, useState } from "react"
import { Box, Text } from '@sqymagma/elements'
import TableLoader from "../../Loaders/TableLoader"
import ModFootnote from "./ModFootnote"

const ModCCAATable = ({ fecha, disclaimer = false }) => {

  const [ tableDataFilterByDate, setTable ] = useState(null)
    useEffect(() => {
      fetch(`https://covid19.secuoyas.io/api/v1/es/ccaa/?fecha=${fecha}`)
      .then((response) => response.json()) // parse JSON from request
      .then((resultData) => {
        setTable(resultData.timeline[0])
      })
      .catch((err) => console.log("Error getting data from API"));
  }, [fecha])

  return (
    tableDataFilterByDate
    ? (
      <Box width={1} my="m" pb="xs">
      <div>
        <Text as="h3" textStyle="l-semi" mb="xs">Desglose por comunidad autónoma</Text>
        <Box overflowX="auto">
        <table id="tablafechaTimeline-2020-03-31" className="visualization-table">
          <thead className="visualization-table-head">
            <tr>
              <td colSpan="5">Total España</td>
            </tr>
          </thead>
          <tbody className="visualization-table-body">
            <Text as="tr" textStyle="table-row">
              <th colSpan="1" className="cell-comunidad">
                Comunidad
              </th>
              <th colSpan="1">Recuperados</th>
              <th colSpan="1">Fallecidos</th>
              <th colSpan="1">Nuevos</th>
              <th colSpan="1">Totales</th>
            </Text>

            {tableDataFilterByDate.regiones.map((region,idx) => {
              const {
                casosRecuperados, 
                casosFallecidos, 
                casosConfirmadosDiario, 
                casosConfirmados 
              } = region.data

              const {nombreLugar} = region;
              
              return (
                region && (
                  <Text as="tr" key={idx} textStyle="table-row">
                    {nombreLugar !== null && <td className="cell-comunidad">{nombreLugar}</td>}
                    {casosRecuperados  !== null && <td className="cell-recuperados">{casosRecuperados >= 0 ? casosRecuperados : "n/a"}</td>}
                    {casosFallecidos  !== null && <td className="cell-fallecidos">{casosFallecidos}</td>}
                    {casosConfirmadosDiario  !== null &&<td className="cell-confirmados">{casosConfirmadosDiario}</td>}
                    {casosConfirmados  !== null && <td className="cell-total">{casosConfirmados}</td>}
                  </Text>
                )
              )
            }) }
          </tbody>
        </table>
        </Box>
      </div>
      <ModFootnote>· Fuente Ministerio Sanidad {disclaimer && <span>| Datos provisionales</span>}</ModFootnote>
    </Box>
    )
    :  <Box width={1}>
          <TableLoader/>
        </Box>
  )
}
export default ModCCAATable
